<template>
  <v-app class="bg-surfaceLight text-onSurfaceLight">
    <v-app-bar app color="primaryContainerLight">
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer" /> -->
      <v-app-bar-title>
        <NuxtLink to="/">
          <LogoHorizontal color="onPrimaryContainerLight" class="pa-2 d-block" />
        </NuxtLink>
      </v-app-bar-title>
    </v-app-bar>
    <v-main class="pt-16">
      <v-container fluid class="pa-0">
        <slot />
      </v-container>
    </v-main>
    <DefaultFooter />
  </v-app>
</template>

<style lang="scss" scoped>
html,
body {
  overscroll-behavior: contain;
}

:deep(.v-input) {
  --v-input-control-height: 24px; // fix for position of floating label on input fields
}
</style>
